<template>
  <v-container fluid>
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar to="/clientes" />
        </v-col>
      </v-row>
    </v-card-actions>
    <cliente-campos
      :carregando-salvar="carregando"
      :validacao-formulario="validacaoFormulario"
      :cliente="cliente"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
import clientes from "@/api/clientes";

export default {
  components: {
    ClienteCampos: () => import("./ClienteCampos"),
  },

  data() {
    return {
      carregando: false,
      validacaoFormulario: {},
      cliente: {},
    };
  },

  mounted() {
    this.buscar();
  },

  methods: {
    async buscar() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);

        const resposta = await clientes.buscar(this.$route.params.cliente);

        let Novoendereco = {
          ...resposta.data.data.enderecos[0],
          endereco_id: resposta.data.data.enderecos[0].id,
        };

        this.cliente = {
          nome: resposta.data.data.pessoa.nome,
          cpf_cnpj: resposta.data.data.pessoa.cpf_cnpj,
          contato: resposta.data.data.pessoa.contato,
          apelido: resposta.data.data.pessoa.apelido,
          telefones: [
            resposta.data.data.pessoa.telefones[0].numero_telefone,
            resposta.data.data.pessoa.telefones[1].numero_telefone,
          ],
          id: resposta.data.data.id,
          ativo: resposta.data.data.ativo,
          tipo_pessoa: resposta.data.data.pessoa.tipo_pessoa,
          endereco: Novoendereco,
        };
      } catch (e) {
        this.$router.push("/clientes", () =>
          this.$snackbar.mostrar({
            cor: "error",
            mensagem: this.$erroApi._(e),
          })
        );
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async salvar(cliente) {
      this.carregando = true;
      this.validacaoFormulario = {};

      console.log("salvar");
      console.log(cliente);

      try {
        await clientes.atualizar(cliente.id, cliente);

        this.$router.push("/clientes", () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: this.$mensagens._("sucesso_editar"),
          })
        );
      } catch (e) {
        if (e.response.status === 422) {
          console.log(e.response);
          this.validacaoFormulario = this.$erroApi.validacao(e);
          return;
        }

        this.$snackbar.mostrar({
          cor: "error",
          mensagem: this.$erroApi._(e),
        });
      } finally {
        this.carregando = false;
      }
    },
  },
};
</script>
